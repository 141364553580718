<template>
    <div id="mapPos" style="width: 100%; height: 100%">
    </div>
  
  <!-- <l-map
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="markerLatLng" />
      <l-circle
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="circle.color"
      />
      <l-polygon
        :lat-lngs="polygon.latlngs"
        :color="polygon.color"
      />
    </l-map>
  
    <template #code>
      {{ codeMarkerPolygon }}
    </template> --></template>
  
  <script>
  /* eslint-disable global-require */
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    LMap, LTileLayer, LMarker, LCircle, LPolygon,
  } from 'vue2-leaflet'
  import { Icon } from 'leaflet'
  import 'leaflet/dist/leaflet.css'
  import { codeMarkerPolygon } from './code'
  import 'leaflet-routing-machine';
  
  
  // eslint-disable-next-line no-underscore-dangle
  delete Icon.Default.prototype._getIconUrl
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  })
  /* eslint-disable global-require */
  
  export default {
    props: {
      height: String,
      width: String,
      locate_service_provider: {},
      billingAreas: Array
    },
    components: {
      LMap,
      LTileLayer,
      LMarker,
      BCardCode,
      LCircle,
      LPolygon,
    },
    computed: {
  
      computedLocatedServiceProviders: {
        get: function () {
          return this.locate_service_provider;
        },
        set: function (providerList) {
          this.locate_service_provider = providerList;
        }
      }
  
    },
    data() {
      return {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 11,
        center: [4.650993870265088, -74.10625732804542],
        markerLatLng: [47.313220, -1.319482],
        circle: {
          center: [4.7032197228260175, -74.03747129203825],
          radius: 4500,
          color: '#EA5455',
        },
        polygon: {
          latlngs: [[47.2263299, -1.6222], [47.21024000000001, -1.6270065], [47.1969447, -1.6136169], [47.18527929999999, -1.6143036], [47.1794457, -1.6098404], [47.1775788, -1.5985107], [47.1676598, -1.5753365], [47.1593731, -1.5521622], [47.1593731, -1.5319061], [47.1722111, -1.5143967], [47.1960115, -1.4841843], [47.2095404, -1.4848709], [47.2291277, -1.4683914], [47.2533687, -1.5116501], [47.2577961, -1.5531921], [47.26828069, -1.5621185], [47.2657179, -1.589241], [47.2589612, -1.6204834], [47.237287, -1.6266632], [47.2263299, -1.6222]],
          color: '#7367F0',
        },
        codeMarkerPolygon,
        map: {},
        located_service_provider: {},
        locatedProviderList: []
      }
    },
    mounted() {
  
      if (this.computedLocatedServiceProviders.hasOwnProperty('customer')) {
  
  
        this.map = L.map('mapPos', {
          center: [this.computedLocatedServiceProviders.customer.location.lat, this.computedLocatedServiceProviders.customer.location.lng],
          // maxBounds: [[39.53,-13.09],[40,6.24]], 
          draggable: false,
          zoom: 11
        });
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '© OpenStreetMap contributors  LAVARCLICK CONSOLE'
        }).addTo(this.map);
  
        // this.computedLocatedServiceProviders.locatedPartners.forEach((locatedPartners) => {

            this.billingAreas.forEach((billArea, indexA) => {

                L.marker([billArea.lat, billArea.lng], {
          title: '',
          draggable: true,
          opacity: 1
        }).bindPopup("<i>AREA " + billArea.id_billing_areas + "</i>").openPopup().addTo(this.map).on('dragend', (e) => {
          this.$emit('setNewLocation', {
            lat: e.target._latlng.lat,
            lng: e.target._latlng.lng,
            index: indexA
          })
        });


            });
  
  
        //OJO this.map.dragging.disable();
  
  
  
  
  
  
  
        // this.locatedProviderList.push([locatedPartners.lat, locatedPartners.lng])
  
        // });
  
        // var myRouting = L.Routing.control({
        //     waypoints: [
        //         L.latLng(this.computedLocatedServiceProviders.customer.location.lat, this.computedLocatedServiceProviders.customer.location.lng),
        //         L.latLng(this.computedLocatedServiceProviders.customer.location.lat, this.computedLocatedServiceProviders.customer.location.lng)
        //     ],
        //     routeWhileDragging: true,
        //     show: false
        // })
        // .addTo(this.map);
  
  
        // myRouting._container.style.display = "None";
  
        // myRouting.on('routesfound', (e) => {
        //     let distance = e.routes[0].summary.totalDistance;
        //     console.log('routing distance: ' + distance, e.routes);
        //     // this.$emit('describeCalculatedProviderRoutes', e.routes);
        // });
  
  
  
      }
  
  
      //         L.marker([4.7032197228260175, -74.03747129203830], {icon: L.icon({
      //     iconUrl: 'leaf-green.png',
      //     shadowUrl: 'leaf-shadow.png',
  
      //     iconSize:     [38, 95], // size of the icon
      //     shadowSize:   [50, 64], // size of the shadow
      //     iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
      //     shadowAnchor: [4, 62],  // the same for the shadow
      //     popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
      // })}).addTo(map);
  
      // console.log(JSON.stringify(this.map));
  
    }
  }
  </script>
  
  <style lang="scss">
  .vue2leaflet-map {
    &.leaflet-container {
      height: 100%;
    }
  }
  </style>
  