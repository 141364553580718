<template>
  <section class="invoice-preview-wrapper">
    <div v-if="flagDescribeOrder">
          <OrderDescriptor :order="computedOrderActive" @closeOrderDescriptor="closeOrderDescriptor"/>
        </div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>
    <div class="pricing-free-trial startTrainingHeaderStyle" >
      <b-row>
        <b-col
          xl="12"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col  xl="12" cols="12">
                  <b-row>
                    <b-col xl="2" class="mt-2">
                      <b-img
              fluid
              :src="require('@/assets/images/logo/logo2.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 120px; width: 120px"
            />
                    </b-col>
                    <b-col xl="7" class="mt-0">
                      <div class="text-primary" style="margin-top: 0px !important;">
                CONTROL DE FACTURACION <b>LAVARCLICK !</b> <span class="text-secondary">Administra Todas las Facturas por Servicios LavarClick</span> 
                      </div>
              <!-- <b-form-group class="blog-search">

<b-input-group class="input-group-merge">
<b-form-input
id="search-input"
v-model="search_query"
placeholder="Buscar categoria o servicio ..."
/>
<b-input-group-append
class="cursor-pointer"
is-text
>
<feather-icon
icon="SearchIcon"
/>
</b-input-group-append>
</b-input-group>
</b-form-group> -->
                    </b-col>
                    <!-- <b-col xl="3" style="padding-top: 0px !important; font-size: 0.8em !important;">
                    ó Crea Una <b-img 
              fluid
              :src="require('@/assets/images/slider/addcat.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 60px; margin-top: 70px !important;"
              v-b-modal.modal-primary-form
            />
                    
                  </b-col> -->
                  </b-row>
                </b-col>
              </b-row>
              
              
              <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
            </div>
            <div
              style="height: 10% !important; width: 50% !important; margin-top: 10px !important; margin-right: -100px !important;">
              Crear Area Precios <b-img fluid :src="require('@/assets/images/slider/addcat.png')"
                class="pricing-trial-img" alt="svg img" style="height: 60px; margin-top: 70px !important;"
                v-b-modal.modal-primary />
              <b-img fluid :src="require('@/assets/images/illustration/33.png')" class="pricing-trial-img"
                alt="svg img" style="height: 150px; padding-left: 50px !important;" />
            </div>
            <!-- images -->
            <!-- <div style="height: 10% !important; width: 30% !important; margin-top: 10px !important; margin-right: -70px !important;">
              
            <b-img
              fluid
              :src="require('@/assets/images/illustration/33.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 200px"
              v-b-modal.modal-primary
            />
            </div> -->
            
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row
      v-if="invoiceData"
      class="invoice-preview" style="margin-top: -60px !important;"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="3"
        md="8" 
      >
      <b-card style="margin-top: 0%; height: 600px; border-radius: 25px;">
      <table class="orderTableStyle" v-for="(invoice, indexInv) in pseReport" v-bind:key="indexInv">
          <tr v-if="indexInv === 0">
            <th colspan="5" style="border-top-left-radius: 25px !important; border-top-right-radius: 25px !important;">
              FACTURAS PSE
            </th>
          </tr>
          <tr v-if="indexInv === 0">
            <th style="width: 10%;">
              Factura
            </th>
            <th style="width: 20%;">
              Estado
            </th>
            <th style="width: 40%;">
              Fecha
            </th>
            <th style="width: 30%;">
              Total
            </th>
            <th>
              Ver
            </th>
          </tr>
          <tr>
            <td style="width: 10%; cursor: pointer;" >
              {{ invoice.id_ecommerce_invoices }}
            </td>
            <td style="width: 20%;">

              <span v-if="invoice.complete === 0">PENDIENTE</span>
              <span v-else>PAGADA</span>

            </td>
            <td style="width: 40%;">

              {{ momentInvoice(new Date(invoice.debited_at*1)).format('YYYY-MM-DD') }}
</td>
              <td style="width: 30%;">
              {{ invoice.ecommerce_amount }}
            </td>
            <td>
              <feather-icon
              
              icon="EyeIcon"
              size="25"
              class="mx-1"
              @click="describeInvoice(invoice)"
              v-b-modal.modal-primary-order
            />
            </td>
          </tr>
        </table>
        <div style="height: 5% !important;"></div>
      </b-card>
    </b-col>
    <b-col xl="6" class="invoice-actions">
        <b-card style="margin-top: 0%; height: 100%; border-radius: 25px;">
          <div style="width: 100%; height: 70%;" v-if="computedBillingAreasList.length > 0">
          <MapBillingAreas :billingAreas="computedBillingAreasList" @setNewLocation="setNewLocation" :locate_service_provider="{
                customer: {
                  location: {
                    lat: newPriceAreaObject.city.lat_city,
                    lng: newPriceAreaObject.city.lng_city,
                  },
                  full_name: newPriceAreaObject.city.city_name
                }
              }" />
</div>

          <table class="orderTableStyle">
          <tr>
            <th colspan="5" style="border-top-left-radius: 25px !important; border-top-right-radius: 25px !important;">
              CONTROL DE AREAS Y PRECIOS
            </th>
          </tr>
          <tr>
            <td colspan="5">
              Ciudad 
              <v-select label="city_name" @input="getBillinAreaByCity" :options="computedCities" />
            </td>
          </tr>
          <tr>
            <td>
              <div>
  <table>
    
    <tr>
      <th style="width: 30%;">
        Nombre
      </th>
      <th>
        Latitud
      </th>
      <th>
        Longitud
      </th>
      <th style="width: 10%;">
        Factor
      </th>
    </tr>
    <tr v-for="(billingAreas, indexB) in computedBillingAreasList" v-bind:key="indexB">
      <td>
        Area {{billingAreas.id_billing_areas}}
      </td>
      <td>
        {{ billingAreas.lat.toString() }}
      </td>
      <td>
        {{ billingAreas.lng.toString() }}
      </td>
      <td>
        <input type="number" @change="updateFactor(indexB, ...arguments)" :value="billingAreas.value" style="width: 100%; text-align: center; font-weight: bold;" />
      </td>
    </tr>
  </table>
</div>

            </td>
          </tr>
          <tr>
            <td>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="updateBillingAreas"
            class="mb-75" block>
            Actualizar Precios x Areas
          </b-button>

            </td>
          </tr>
          <!-- Button: Send Invoice -->
</table>



        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="3"
        md="8" 
      >
      <b-card style="margin-top: 0%; height: 600px; border-radius: 25px;">
        <table class="orderTableStyle" v-for="(invoice, indexInv) in report" v-bind:key="indexInv">
          <tr v-if="indexInv === 0">
            <th colspan="5" style="border-top-left-radius: 25px !important; border-top-right-radius: 25px !important;">
              FACTURAS COMISIONES
            </th>
          </tr>
          <tr v-if="indexInv === 0">
            <th style="width: 10%;">
              Factura
            </th>
            <th style="width: 10%;">
              Partner
            </th>
            <th style="width: 20%;">
              Estado
            </th>
            <th style="width: 30%;">
              Fecha
            </th>
            <th style="width: 30%;">
              Total
            </th>
          </tr>
          <tr>
            <td style="width: 10%;">
              {{ invoice.id_ecommerce_invoices }}
            </td>
            <td style="width: 10%;">
              {{ invoice.fk_provider }}
            </td>
            <td style="width: 20%;">

              <span v-if="invoice.complete === 0">PENDIENTE</span>
              <span v-else>PAGADA</span>

            </td>
            <td style="width: 40%;">

              {{ momentInvoice(new Date(invoice.debited_at*1)).format('YYYY-MM-DD') }}
</td>
              <td style="width: 30%;">
              {{ invoice.ecommerce_amount }}
            </td>
          </tr>
        </table>
      </b-card>
        </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment />
    <NewPriceAreaForm />
  </section>
    
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import moment from 'moment';
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BImg, BModal, VBModal
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import invoiceStoreModule from './invoiceStoreModule'
import InvoiceSidebarSendInvoice from './InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from './InvoiceSidebarAddPayment.vue'
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import MapBillingAreas from '@/lavarclick/components/maps/MapBillingAreas.vue';
import vSelect from 'vue-select';
import OrderDescriptor from '@/lavarclick/components/services/OrderDescriptor.vue';
import NewPriceAreaForm from './NewPriceAreaForm.vue';

export default {
  directives: {
    Ripple,
    'b-toggle': VBModal,
  },
  components: {
    BRow,
    BCol, BModal, VBModal, NewPriceAreaForm,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BImg,
    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
    MapBillingAreas,
    vSelect,
    OrderDescriptor
  },
  methods: {

    
    closeOrderDescriptor(eventx) {

this.flagDescribeOrder = false;

},

    async getBillinAreaByCity(city) {
      this.computedBillingAreasList = [];

      setTimeout(() => {
        this.eCommerce.getECommerceBillingAreas({
      id_city: city.id_ecommerce_cities
    }).then((billingAreas) => {
      this.newPriceAreaObject.city = city;
      this.computedBillingAreasList = billingAreas;
    })
        
      }, 100);

    },

    async updateFactor(indexA, newVal) {

      const buff = this.computedBillingAreasList[indexA].value;

      if( newVal.target.value*1 > 0.05 && newVal.target.value*1 <= 3) {

         this.computedBillingAreasList[indexA].value = newVal.target.value*1;

      }else {

        this.computedBillingAreasList[indexA].value = buff;

      }
    },

    async updateBillingAreas() {

      this.eCommerce.updateBillingAreas({
        billingAreas: this.computedBillingAreasList
      }).then((updateResult) => {
        // this.computedBillingAreasList = updateResult;
        console.log('listones jijiji ');

      });


    },

    async setNewLocation(newLocation) {

      this.computedBillingAreasList[newLocation.index].lat = newLocation.lat;
      this.computedBillingAreasList[newLocation.index].lng = newLocation.lng;
      console.log('paz total ', newLocation);
    },

    async describeInvoice(orderInvoice) {

      this.eCommerce.getECommerceOrders({ get_all: false,
                get_order_id: orderInvoice.id_order,
                get_quotes: false,
                get_customer_id: 0 }).then((invoiceOrder) => {
                  console.log('punta ', invoiceOrder);
                  this.computedOrderActive = invoiceOrder[0].order;
          this.flagDescribeOrder = true;
                  console.log('buen traje', invoiceOrder, orderInvoice);
    
        });
    
    }

  },
  setup() {
    const momentInvoice = require('moment');
    let newPriceAreaObject = ref({
            city: {},
            priceAreas: []
        })
    let columns = ref([
      {
        label: "Product",
        field: "product",
      },
      {
        label: "Price",
        field: "price",
        dataFormat: ""
      },
      {
        label: "Quantity",
        field: "quantity",
      },
      {
        label: "Country",
        field: "address.country",
      },
      {
        label: "City",
        field: "address.city",
      }
    ]);
    let data = ref([
      {
        product: "Beverage",
        price: 10,
        quantity: 2,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Snack",
        price: 12,
        quantity: 6,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Beverage",
        price: 10,
        quantity: 5,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      },
      {
        product: "Snack",
        price: 12,
        quantity: 3,
        address: {
          country: 'Armenia',
          city: 'Yerevan',
        }
      }
    ]);
    const billDescription = ref({
      partner: {
        name
      },
      items: [],
      customer: {} 
    })
    const computedBillDescription = computed({
      get: () => { return billDescription.value},
      set: (newDes) => {
        billDescription.value = newDes;
      }
    })
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const eCommerce = new ECommerce();
    const accountsManager = new AccountsManager();
    let report = ref([]);
    let pseReport = ref([]);

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-invoice/fetchInvoice', { id: 4987 })
      .then(response => {
        invoiceData.value = response.data.invoice
        paymentDetails.value = response.data.paymentDetails
      })
      .catch(error => {
        if (error.response.status === 404) {
          invoiceData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);

    let billingAreasList = ref([]);
    const computedBillingAreasList = computed({
      get: () => { return billingAreasList.value },
      set: (newList) => {
        billingAreasList.value = newList;
      }
    });

    let flagDescribeOrder = ref(false);
    let orderActive = ref();
    const computedOrderActive = computed({
        get: () => {
          return orderActive.value;
        },
        set: (order) => {
          orderActive.value = order;
        }
      });
      const cities = ref([]);
    const computedCities = computed({
      get: () => { return cities.value },
      set: (newList) => {
        cities.value = newList;
      }
    });

    return {
      invoiceData, momentInvoice,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      eCommerce, report, computedBillDescription, pseReport, columns, data, computedBillingAreasList, computedOrderActive, flagDescribeOrder,
      newPriceAreaObject, computedCities, accountsManager
    }
  },
  mounted() {

    this.accountsManager.getCities(44).then((citiesList) => {
            this.computedCities = citiesList;
//            console.log('ciudades ', citiesList)
        })

    this.eCommerce.getECommerceInvoices({
                            id_provider: 0,
                            type: 1
                        }).then((providerInvoices) => {
                          this.pseReport = providerInvoices;
                            console.log('interior', providerInvoices);

                        })

                        this.eCommerce.getECommerceInvoices({
                            id_provider: 0,
                            type: 2
                        }).then((providerInvoices) => {
                          this.report = providerInvoices;
                            console.log('interior2', providerInvoices);

                        })



  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
  .startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: MavenPro !important;
}
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.orderTableStyle {
  margin-left: 2% !important;
  font-family: MavenPro !important;
  width: 95% !important;
  margin-top: 0px !important;

}

.orderTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 1.1em !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: center !important;
  font-family: MavenPro !important;
}

.orderTableStyle td {
  border: #4e95f4 0px solid;
  text-align: center !important;
  font-family: MavenPro !important;
  color: #000000;

}

/* provide some minimal visual accomodation for IE8 and below */
.orderTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.orderTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.orderTableStyle tr:nth-child(even) {
  background: #f2faf0;
}

</style>
