var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalCategoryForm",attrs:{"id":"modal-primary","hide-footer":"","modal-class":"modal-primary","centered":"","size":"lg","title":"Formulario Nueva Area de Precios LavarClick !"},on:{"hidden":_vm.editCategoryHidden}},[_c('b-card',{staticStyle:{"margin-top":"0%","height":"100%","border-radius":"25px"}},[_c('div',{staticStyle:{"width":"100% !important"}},[_c('b-row',[_c('b-col',{staticClass:"invoice-actions",attrs:{"xl":"5"}},[_c('table',{staticClass:"orderTableStyle"},[_c('tr',[_c('th',{staticStyle:{"border-top-left-radius":"25px !important","border-top-right-radius":"25px !important"},attrs:{"colspan":"5"}},[_vm._v(" CONTROL DE AREAS Y PRECIOS ")])]),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v(" Ciudad "),_c('v-select',{attrs:{"label":"city_name","options":_vm.computedCities},on:{"input":_vm.getBillinAreaByCity}})],1)]),_c('tr',[_c('td',{attrs:{"colspan":"5"}},[_vm._v(" Ciudad "),_c('v-select',{attrs:{"label":"city_name","options":_vm.computedCities},on:{"input":_vm.newPriceArea}})],1)]),_c('tr',[_c('td',[_c('div',[_c('table',[_c('tr',[_c('th',{staticStyle:{"width":"30%"}},[_vm._v(" Nombre ")]),_c('th',[_vm._v(" Latitud ")]),_c('th',[_vm._v(" Longitud ")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v(" Factor ")])]),_vm._l((_vm.computedBillingAreasList),function(billingAreas,indexB){return _c('tr',{key:indexB},[_c('td',[_vm._v(" Area "+_vm._s(billingAreas.id_billing_areas)+" ")]),_c('td',[_vm._v(" "+_vm._s(billingAreas.lat.toString().substring(0, 8))+" ")]),_c('td',[_vm._v(" "+_vm._s(billingAreas.lng.toString().substring(0, 8))+" ")]),_c('td',[_c('input',{staticStyle:{"width":"100%","text-align":"center","font-weight":"bold"},attrs:{"type":"number"},domProps:{"value":billingAreas.value},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateFactor.apply(void 0, [ indexB ].concat( argsArray ))}}})])])})],2)])])]),_c('tr',[_c('td',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"primary","block":""},on:{"click":_vm.createBillingAreas}},[_vm._v(" Crear Area de Precios ")])],1)])])]),_c('b-col',{staticClass:"invoice-actions",attrs:{"xl":"7"}},[(_vm.computedBillingAreasList.length > 0)?_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('MapBillingAreas',{attrs:{"billingAreas":_vm.computedBillingAreasList,"locate_service_provider":{
                customer: {
                  location: {
                    lat: _vm.newPriceAreaObject.city.lat_city,
                    lng: _vm.newPriceAreaObject.city.lng_city,
                  },
                  full_name: _vm.newPriceAreaObject.city.city_name
                }
              }},on:{"setNewLocation":_vm.setNewLocation}})],1):_vm._e()])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }