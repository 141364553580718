<template>
    <b-modal
      id="modal-primary"
      hide-footer
      modal-class="modal-primary"
      centered
      size="lg"
      title="Formulario Nueva Area de Precios LavarClick !"
      ref="modalCategoryForm" @hidden="editCategoryHidden">
      <b-card style="margin-top: 0%; height: 100%; border-radius: 25px;">
      <div style="width: 100% !important">    
        
<b-row>
    <b-col xl="5" class="invoice-actions">

          <table class="orderTableStyle">
          <tr>
            <th colspan="5" style="border-top-left-radius: 25px !important; border-top-right-radius: 25px !important;">
              CONTROL DE AREAS Y PRECIOS
            </th>
          </tr>
          <tr>
            <td colspan="5">
              Ciudad 
              <v-select label="city_name" @input="getBillinAreaByCity" :options="computedCities" />
            </td>
          </tr>
          <tr>
            <td colspan="5">
              Ciudad 
              <v-select label="city_name" :options="computedCities" @input="newPriceArea" />
            </td>
          </tr>



          <tr>
            <td>
              <div>
  <table>
    
    <tr>
      <th style="width: 30%;">
        Nombre
      </th>
      <th>
        Latitud
      </th>
      <th>
        Longitud
      </th>
      <th style="width: 10%;">
        Factor
      </th>
    </tr>
    <tr v-for="(billingAreas, indexB) in computedBillingAreasList" v-bind:key="indexB">
      <td>
        Area {{billingAreas.id_billing_areas}}
      </td>
      <td>
        {{ billingAreas.lat.toString().substring(0, 8) }}
      </td>
      <td>
        {{ billingAreas.lng.toString().substring(0, 8) }}
      </td>
      <td>
        <input type="number" @change="updateFactor(indexB, ...arguments)" :value="billingAreas.value" style="width: 100%; text-align: center; font-weight: bold;" />
      </td>
    </tr>
  </table>
</div>

            </td>
          </tr>
          <tr>
            <td>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="createBillingAreas"
            class="mb-75" block>
            Crear Area de Precios
          </b-button>

            </td>
          </tr>
          <!-- Button: Send Invoice -->
</table>
</b-col>
<b-col xl="7" class="invoice-actions">
<div style="width: 100%; height: 100%;" v-if="computedBillingAreasList.length > 0">
          <MapBillingAreas :billingAreas="computedBillingAreasList" @setNewLocation="setNewLocation" :locate_service_provider="{
                customer: {
                  location: {
                    lat: newPriceAreaObject.city.lat_city,
                    lng: newPriceAreaObject.city.lng_city,
                  },
                  full_name: newPriceAreaObject.city.city_name
                }
              }" />
</div>



      </b-col>

</b-row>

      <!-- Tab: Account -->


  </div>
</b-card>


      </b-modal>
</template>
<script>

import {
  BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal, VBModal, BButton
} from 'bootstrap-vue'
import User from '@/lavarclick/ecommerce/User.ts';
import store from '@/store'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';
import MapBillingAreas from '@/lavarclick/components/maps/MapBillingAreas.vue';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';
import { ref, onUnmounted, computed } from '@vue/composition-api';
import vSelect from 'vue-select';

export default {

    components: {

        BTab, BTabs, BCard, BAlert, BLink, BImg, BRow, BCol, BModal, MapBillingAreas, vSelect, BButton, VBModal
    },

    setup() {

     
        let billingAreasList = ref([]);
    const computedBillingAreasList = computed({
      get: () => { return billingAreasList.value },
      set: (newList) => {
        billingAreasList.value = newList;
      }
    });
    const cities = ref([]);
    const computedCities = computed({
      get: () => { return cities.value },
      set: (newList) => {
        cities.value = newList;
      }
    });
        let newUserObject = new User();
       
        const userData = JSON.parse(localStorage.getItem('userData'));
        let customerAreasList = [];
        const accountsManager = new AccountsManager();
        let flag = false;
        let countries = [];
        let newPriceAreaObjectList = ref([])
        let newPriceAreaObject = ref({
            city: {},
            priceAreas: []
        })
        const eCommerce = new ECommerce();

        return {
            newUserObject,
            customerAreasList,
            userData,
            accountsManager,
            flag,
            countries, 
            computedBillingAreasList,
            eCommerce, computedCities, newPriceAreaObjectList, newPriceAreaObject
            
            
        }
    },
    mounted() {

        this.accountsManager.getCities(44).then((citiesList) => {
            this.computedCities = citiesList;
//            console.log('ciudades ', citiesList)
        })

        
    },
    methods: {

        async newPriceArea(city) {
            this.newPriceAreaObject.city = city;
            this.newPriceAreaObject.priceAreas = [];
            this.computedBillingAreasList = [];

            setTimeout(() => {
                this.newPriceAreaObject.priceAreas.push({
                
                "id_billing_areas": 1,
                "fk_city_ecommerce_bill_areas": city.id_ecommerce_cities,
                "lat": city.lat_city,
                "lng": city.lng_city,
                "value": 1.0
            
        },
        {
            
            "id_billing_areas": 2,
            "fk_city_ecommerce_bill_areas": city.id_ecommerce_cities,
            "lat": city.lat_city,
            "lng": city.lng_city,
            "value": 1.0
        
    },{
            
            "id_billing_areas": 3,
            "fk_city_ecommerce_bill_areas": city.id_ecommerce_cities,
            "lat": city.lat_city,
            "lng": city.lng_city,
            "value": 1.0
        
    },{
            
            "id_billing_areas": 4,
            "fk_city_ecommerce_bill_areas": city.id_ecommerce_cities,
            "lat": city.lat_city,
            "lng": city.lng_city,
            "value": 1.0
        
    });

        this.computedBillingAreasList = this.newPriceAreaObject.priceAreas;                
            }, 100);
            

        },


        async getBillinAreaByCity(city) {
            

this.eCommerce.getECommerceBillingAreas({
id_city: city.id_ecommerce_cities
}).then((billingAreas) => {

this.computedBillingAreasList = billingAreas;

})

},

async updateFactor(indexA, newVal) {

const buff = this.computedBillingAreasList[indexA].value;

if( newVal.target.value*1 > 0.05 && newVal.target.value*1 <= 3) {

   this.computedBillingAreasList[indexA].value = newVal.target.value*1;

}else {

  this.computedBillingAreasList[indexA].value = buff;

}
},

async updateBillingAreas() {

this.eCommerce.updateBillingAreas({
  billingAreas: this.computedBillingAreasList
}).then((updateResult) => {
  // this.computedBillingAreasList = updateResult;
  console.log('listones jijiji ');

});


},

async createBillingAreas() {

this.eCommerce.createBillingAreas({
  billingAreas: this.computedBillingAreasList
}).then((updateResult) => {
  // this.computedBillingAreasList = updateResult;
  console.log('listones jijiji ');

});


},

async setNewLocation(newLocation) {
    this.computedBillingAreasList[newLocation.index].lat = newLocation.lat;
    this.computedBillingAreasList[newLocation.index].lng = newLocation.lng;
},

      async loadMap() {
console.log('hola');

},
        editCategoryHidden(eventx) {

        },
        updateUserInfo(index, eventx) {

            this.$emit('updateUser', {
                infoTypeIndex: index,
                userData: this.newUserObject
            })

        }
    }
}

</script>
<style lang="scss">


</style>